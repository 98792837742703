import randomGen from "random-seed";
import moment from "moment";

export const HANDS = {
  PAIR: "PAIR",
  TWO_PAIR: "TWO_PAIR",
  FLUSH: "FLUSH",
  THREE_OF_KIND: "THREE",
  FULL_HOUSE: "FULL_HOUSE",
  STRAIGHT: "STRAIGHT",
  FOUR_OF_KIND: "FOUR",
  STRAIGHT_FLUSH: "STRAIGHT_FLUSH",
  ROYAL_FLUSH: "ROYAL_FLUSH",
  NOTHING: "NOTHING",
};

export const SCORE_UK = {
  PAIR: 1,
  TWO_PAIR: 3,
  FLUSH: 5,
  THREE_OF_KIND: 6,
  FULL_HOUSE: 10,
  STRAIGHT: 12,
  FOUR_OF_KIND: 16,
  STRAIGHT_FLUSH: 30,
  ROYAL_FLUSH: 30,
};

export const SCORE_US = {
  PAIR: 2,
  TWO_PAIR: 5,
  FLUSH: 20,
  THREE_OF_KIND: 10,
  FULL_HOUSE: 25,
  STRAIGHT: 15,
  FOUR_OF_KIND: 50,
  STRAIGHT_FLUSH: 75,
  ROYAL_FLUSH: 100,
};

const SUIT = {
  HEART: { char: "♥️", css: "heart" },
  DIAMOND: { char: "♦️", css: "diamond" },
  SPADE: { char: "♠️", css: "spade" },
  CLUB: { char: "♣️", css: "club" },
};

export const CARD_SUITS = [SUIT.HEART, SUIT.DIAMOND, SUIT.SPADE, SUIT.CLUB];
export const CARDS_NUMBERS = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"];

const STRAIGHTS = {
  ACE_HIGH: "ACE_HIGH",
  STRAIGHT: "STRAIGHT",
};

const ROWCOL = [...Array(5).keys()];

export const shuffle = (seed, array) => {
  const random = randomGen.create(seed);
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(random.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const shufflePreRandom = (randoms, array) => {
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    currentIndex--;
    const randomIndex = Math.floor(randoms[currentIndex] * currentIndex);

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const cardNumberIndex = (cardIndex) => cardIndex - Math.floor(cardIndex / 13) * 13;
export const cardNumber = (cardIndex) => CARDS_NUMBERS[cardNumberIndex(cardIndex)];
export const cardSuitIndex = (cardIndex) => Math.floor(cardIndex / 13);
export const cardSuit = (cardIndex) => CARD_SUITS[cardSuitIndex(cardIndex)];

export const countNumbers = (rowcol) =>
  rowcol.reduce((counts, rc) => {
    if (typeof rc !== "number") {
      return counts;
    }

    const newCounts = [...counts];
    newCounts[cardNumberIndex(rc)] = (newCounts[cardNumberIndex(rc)] || 0) + 1;
    return newCounts;
  }, []);

export const countSuits = (rowcol) =>
  rowcol.reduce((counts, rc) => {
    if (typeof rc !== "number") {
      return counts;
    }

    const newCounts = [...counts];
    newCounts[cardSuitIndex(rc)] = (newCounts[cardSuitIndex(rc)] || 0) + 1;
    return newCounts;
  }, []);

export const countFree = (rowcol) => 5 - rowcol.filter((rc) => typeof rc === "number").length;

const straightType = (rowcol) => {
  if (rowcol.find((rc) => typeof rc !== "number")) {
    return null;
  }

  const sorted = rowcol.map(cardNumberIndex).sort((a, b) => a - b);

  if (
    sorted[1] === sorted[0] + 1 &&
    sorted[2] === sorted[1] + 1 &&
    sorted[3] === sorted[2] + 1 &&
    sorted[4] === sorted[3] + 1
  ) {
    return STRAIGHTS.STRAIGHT;
  } else if (
    sorted[0] === 0 &&
    sorted[4] === 12 &&
    sorted[2] === sorted[1] + 1 &&
    sorted[3] === sorted[2] + 1 &&
    sorted[4] === sorted[3] + 1
  ) {
    return STRAIGHTS.ACE_HIGH;
  } else {
    return null;
  }
};

const handRowCol = (rowcol) => {
  const numCounts = countNumbers(rowcol);
  const suitCounts = countSuits(rowcol);

  const fourOfKind = numCounts.find((count) => count === 4);
  const threeOfKind = numCounts.find((count) => count === 3);
  const pairs = numCounts.filter((count) => count === 2).length;
  const flush = suitCounts.find((count) => count === 5);
  const straight = straightType(rowcol);

  const free = countFree(rowcol);
  const noHand = free === 0 ? HANDS.NOTHING : null;

  if (flush && straight === STRAIGHTS.ACE_HIGH) {
    return ROWCOL.map(() => HANDS.ROYAL_FLUSH);
  }
  if (flush && straight) {
    return ROWCOL.map(() => HANDS.STRAIGHT_FLUSH);
  }
  if (flush) {
    return ROWCOL.map(() => HANDS.FLUSH);
  }
  if (straight) {
    return ROWCOL.map(() => HANDS.STRAIGHT);
  }
  if (fourOfKind) {
    const numIndex = numCounts.findIndex((count) => count === 4);
    return ROWCOL.map((i) => (cardNumberIndex(rowcol[i]) === numIndex ? HANDS.FOUR_OF_KIND : noHand));
  }
  if (threeOfKind && pairs === 1) {
    return ROWCOL.map(() => HANDS.FULL_HOUSE);
  }
  if (threeOfKind) {
    const numIndex = numCounts.findIndex((count) => count === 3);
    return ROWCOL.map((i) => (cardNumberIndex(rowcol[i]) === numIndex ? HANDS.THREE_OF_KIND : noHand));
  }
  if (pairs === 2) {
    return ROWCOL.map((i) => (numCounts[cardNumberIndex(rowcol[i])] === 2 ? HANDS.TWO_PAIR : noHand));
  }
  if (pairs === 1) {
    const numIndex = numCounts.findIndex((count) => count === 2);
    return ROWCOL.map((i) => (cardNumberIndex(rowcol[i]) === numIndex ? HANDS.PAIR : noHand));
  }

  return ROWCOL.map(() => noHand);
};

const scoreRowCol = (rowcol, scoring) => {
  const numCounts = countNumbers(rowcol);
  const suitCounts = countSuits(rowcol);

  const fourOfKind = numCounts.find((count) => count === 4);
  const threeOfKind = numCounts.find((count) => count === 3);
  const pairs = numCounts.filter((count) => count === 2).length;
  const flush = suitCounts.find((count) => count === 5);
  const straight = straightType(rowcol);

  let score = 0;

  if (flush && straight === STRAIGHTS.ACE_HIGH) {
    score = Math.max(score, scoring["ROYAL_FLUSH"]);
  }
  if (flush && straight) {
    score = Math.max(score, scoring["STRAIGHT_FLUSH"]);
  }
  if (flush) {
    score = Math.max(score, scoring["FLUSH"]);
  }
  if (straight) {
    score = Math.max(score, scoring["STRAIGHT"]);
  }
  if (fourOfKind) {
    score = Math.max(score, scoring["FOUR_OF_KIND"]);
  }
  if (threeOfKind && pairs === 1) {
    score = Math.max(score, scoring["FULL_HOUSE"]);
  }
  if (threeOfKind) {
    score = Math.max(score, scoring["THREE_OF_KIND"]);
  }
  if (pairs === 2) {
    score = Math.max(score, scoring["TWO_PAIR"]);
  }
  if (pairs === 1) {
    score = Math.max(score, scoring["PAIR"]);
  }

  return score;
};

export const boardRowIndex = (index) => Math.floor(index / 5);
export const boardColumnIndex = (index) => index % 5;

export const boardRow = (board, index) => board.slice(index * 5, (index + 1) * 5);
export const boardColumn = (board, index) => [...Array(5).keys()].map((i) => board[index + i * 5]);

export const handRow = (board, index) => handRowCol(boardRow(board, index));
export const handColumn = (board, index) => handRowCol(boardColumn(board, index));

export const scoreRow = (board, index, scoring) => scoreRowCol(boardRow(board, index), scoring);
export const scoreColumn = (board, index, scoring) => scoreRowCol(boardColumn(board, index), scoring);

export const handBoard = (board) => {
  const rowHands = [...Array(5).keys()].map((i) => handRow(board, i));
  const columnHands = [...Array(5).keys()].map((i) => handColumn(board, i));

  return {
    rows: rowHands,
    columns: columnHands,
  };
};

export const scoreBoard = (board, scoring) => {
  const rowScores = [...Array(5).keys()].map((i) => scoreRow(board, i, scoring));
  const columnScores = [...Array(5).keys()].map((i) => scoreColumn(board, i, scoring));

  const rowScore = rowScores.reduce((sum, score) => sum + score, 0);
  const columnScore = columnScores.reduce((sum, score) => sum + score, 0);

  return rowScore + columnScore;
};

export const calculateStats = (stats, score, robbieScore, rosieScore, rockyScore) => {
  const highScore = score > (stats.highScore ?? 0) ? score : stats.highScore ?? 0;
  const newHighScore = highScore > (stats.highScore ?? 0);

  const robbieStreak =
    score > robbieScore ? (stats.robbieStreak ?? 0) + 1 : score === robbieScore ? stats.robbieStreak ?? 0 : 0;
  const rosieStreak =
    score > rosieScore ? (stats.rosieStreak ?? 0) + 1 : score === rosieScore ? stats.rosieStreak ?? 0 : 0;
  const rockyStreak =
    score > rockyScore ? (stats.rockyStreak ?? 0) + 1 : score === rockyScore ? stats.rockyStreak ?? 0 : 0;

  const allStreak =
    score > robbieScore && score > rosieScore && score > rockyScore
      ? (stats.allStreak ?? 0) + 1
      : score === robbieScore && score === rosieScore && score === rockyScore
      ? stats.allStreak ?? 0
      : 0;

  const newRobbieStreak = robbieStreak > (stats.maxRobbieStreak ?? 0);
  const newRosieStreak = rosieStreak > (stats.maxRosieStreak ?? 0);
  const newRockyStreak = rockyStreak > (stats.maxRockyStreak ?? 0);
  const newAllStreak = allStreak > (stats.maxAllStreak ?? 0);

  const robbieWld = stats.robbieWld || { w: 0, l: 0, d: 0 };
  const rosieWld = stats.rosieWld || { w: 0, l: 0, d: 0 };
  const rockyWld = stats.rockyWld || { w: 0, l: 0, d: 0 };

  const beat = (score > robbieScore ? 1 : 0) + (score > rosieScore ? 1 : 0) + (score > rockyScore ? 1 : 0);

  return {
    version: stats.version || 3,
    games: (stats.games ?? 0) + 1,

    highScore,
    newHighScore,

    totalScore: (stats.totalScore ?? 0) + score,
    totalRobbieScore: (stats.totalRobbieScore ?? 0) + robbieScore,
    totalRosieScore: (stats.totalRosieScore ?? 0) + rosieScore,
    totalRockyScore: (stats.totalRockyScore ?? 0) + rockyScore,

    robbieWins: (stats.robbieWins ?? 0) + (score > robbieScore ? 1 : 0),
    rosieWins: (stats.rosieWins ?? 0) + (score > rosieScore ? 1 : 0),
    rockyWins: (stats.rockyWins ?? 0) + (score > rockyScore ? 1 : 0),

    robbieStreak,
    rosieStreak,
    rockyStreak,
    allStreak,

    maxRobbieStreak: Math.max(stats.maxRobbieStreak ?? 0, robbieStreak),
    maxRosieStreak: Math.max(stats.maxRosieStreak ?? 0, rosieStreak),
    maxRockyStreak: Math.max(stats.maxRockyStreak ?? 0, rockyStreak),
    maxAllStreak: Math.max(stats.maxAllStreak ?? 0, allStreak),

    newRobbieStreak,
    newRosieStreak,
    newRockyStreak,
    newAllStreak,

    robbieWld: {
      w: robbieWld.w + (score > robbieScore ? 1 : 0),
      l: robbieWld.l + (score < robbieScore ? 1 : 0),
      d: robbieWld.d + (score === robbieScore ? 1 : 0),
    },
    rosieWld: {
      w: rosieWld.w + (score > rosieScore ? 1 : 0),
      l: rosieWld.l + (score < rosieScore ? 1 : 0),
      d: rosieWld.d + (score === rosieScore ? 1 : 0),
    },
    rockyWld: {
      w: rockyWld.w + (score > rockyScore ? 1 : 0),
      l: rockyWld.l + (score < rockyScore ? 1 : 0),
      d: rockyWld.d + (score === rockyScore ? 1 : 0),
    },

    beat3Robot: (stats.beat3Robot ?? 0) + (beat === 3 ? 1 : 0),
    beat2Robot: (stats.beat2Robot ?? 0) + (beat === 2 ? 1 : 0),
    beat1Robot: (stats.beat1Robot ?? 0) + (beat === 1 ? 1 : 0),
    beat0Robot: (stats.beat0Robot ?? 0) + (beat === 0 ? 1 : 0)
  };
};

export const initialCalulateRobotBeats = (stats, gamesHistory) => {
  let beat3Robot = 0;
  let beat2Robot = 0;
  let beat1Robot = 0;
  let beat0Robot = 0;

  gamesHistory.forEach((game) => {
    const beat =
      (game.score > game.robbieScore ? 1 : 0) +
      (game.score > game.rosieScore ? 1 : 0) +
      (game.score > game.rockyScore ? 1 : 0);

    if (beat === 3) {
      beat3Robot += 1;
    } else if (beat === 2) {
      beat2Robot += 1;
    } else if (beat === 1) {
      beat1Robot += 1;
    } else {
      beat0Robot += 1;
    }
  });

  return {
    ...stats,
    version: 3,
    beat3Robot,
    beat2Robot,
    beat1Robot,
    beat0Robot,
  };
};

export const initialCalculateDailyStreak = (dateStr, gamesHistory) => {
  const streak = gamesHistory
    .map((game) => moment(game.date, "YYYY-M-D"))
    .reduce(
      (acc, val) => ({
        prevdate: val,
        count: (acc.prevdate && val.diff(acc.prevdate, "days")) === 1 ? acc.count + 1 : 1,
        max:
          acc.prevdate && val.diff(acc.prevdate, "days") === 1 && acc.count + 1 > acc.max
            ? acc.count + 1
            : Math.max(acc.max, 1),
      }),
      {
        prevdate: null,
        count: 1,
        max: 0,
      }
    );

  return {
    max: streak.max,
    current: streak.prevdate ? (moment(dateStr, "YYYY-M-D").diff(streak.prevdate, "days") === 1 ? streak.count : 0) : 0,
  };
};
