import { Fragment } from "react";
import "./Stats.scss";

const Stats = ({ stats, dailyStreak, daily, scoreSystem, robots, onClose }) => {
  const games = stats.games ?? 0;
  const robbieScoreDiff =
    games === 0 ? 0 : Math.round((((stats.totalScore ?? 0) - (stats.totalRobbieScore ?? 0)) / games) * 10) / 10;
  const rosieScoreDiff =
    games === 0 ? 0 : Math.round((((stats.totalScore ?? 0) - (stats.totalRosieScore ?? 0)) / games) * 10) / 10;
  const rockyScoreDiff =
    games === 0 ? 0 : Math.round((((stats.totalScore ?? 0) - (stats.totalRockyScore ?? 0)) / games) * 10) / 10;
  const beatCount =
    (stats.beat3Robot ?? 0) + (stats.beat2Robot ?? 0) + (stats.beat1Robot ?? 0) + (stats.beat0Robot ?? 0);
  return (
    <div className="stats__container" onClick={onClose}>
      <div>
        <div className="stats__stats">
          <div className="stats__stat">Scoring:</div>
          <div className="stats__main_value">{scoreSystem === "UK" ? "UK 🇬🇧" : "US 🇺🇸"}</div>
          <div className="stats__stat">Games Played:</div>
          <div className="stats__main_value">{stats.games ?? 0}</div>
          <div className="stats__stat">Average Score:</div>
          <div className="stats__main_value">
            {games === 0 ? 0 : Math.round(((stats.totalScore ?? 0) / games) * 100) / 100}
          </div>
          <div className="stats__stat">High Score:</div>
          <div className="stats__main_value">{stats.highScore ?? 0}</div>

          <div className="stats__stat stats__wld">Robots Beaten</div>
          <div className="stats__stat stats__wld">Beat %</div>
          <div className="stats__stat stats__wld"></div>
          <div>3 Robots:</div>
          <div>{beatCount === 0 ? 0 : Math.round(((stats.beat3Robot ?? 0) / beatCount) * 100)}%</div>
          <div />
          <div>2 Robots:</div>
          <div>{beatCount === 0 ? 0 : Math.round(((stats.beat2Robot ?? 0) / beatCount) * 100)}%</div>
          <div />
          <div>1 Robot:</div>
          <div>{beatCount === 0 ? 0 : Math.round(((stats.beat1Robot ?? 0) / beatCount) * 100)}%</div>
          <div />
          <div>0 Robots:</div>
          <div>{beatCount === 0 ? 0 : Math.round(((stats.beat0Robot ?? 0) / beatCount) * 100)}%</div>
          <div />

          <div className="stats__stat stats__streak">Win Streaks</div>
          <div className="stats__stat stats__streak">Current</div>
          <div className="stats__stat stats__streak">Max</div>
          <div>vs All Robots:</div>
          <div>{stats.allStreak ?? 0}</div>
          <div>{stats.maxAllStreak ?? 0}</div>
          <div>vs {robots[0].name}:</div>
          <div>{stats.robbieStreak ?? 0}</div>
          <div>{stats.maxRobbieStreak ?? 0}</div>
          <div>vs {robots[1].name}:</div>
          <div>{stats.rosieStreak ?? 0}</div>
          <div>{stats.maxRosieStreak ?? 0}</div>
          <div>vs {robots[2].name}:</div>
          <div>{stats.rockyStreak ?? 0}</div>
          <div>{stats.maxRockyStreak ?? 0}</div>

          {daily && (
            <Fragment>
              <div className="stats__stat stats__daily-streak">Daily Streak</div>
              <div className="stats__daily-streak">{dailyStreak.current ?? "?"}</div>
              <div className="stats__daily-streak">{dailyStreak.max ?? "?"}</div>
            </Fragment>
          )}

          <div className="stats__stat stats__wld">Wins</div>
          <div className="stats__stat stats__wld">W / L / D</div>
          <div className="stats__stat stats__wld">Win %</div>
          <div>vs {robots[0].name}:</div>
          <div>
            {stats.robbieWld ? `${stats.robbieWld.w} / ${stats.robbieWld.l} / ${stats.robbieWld.d}` : "0 / 0 / 0"}
          </div>
          <div>{games === 0 ? 0 : Math.round(((stats.robbieWins ?? 0) / games) * 100)}%</div>
          <div>vs {robots[1].name}:</div>
          <div>{stats.rosieWld ? `${stats.rosieWld.w} / ${stats.rosieWld.l} / ${stats.rosieWld.d}` : "0 / 0 / 0"}</div>
          <div>{games === 0 ? 0 : Math.round(((stats.rosieWins ?? 0) / games) * 100)}%</div>
          <div>vs {robots[2].name}:</div>
          <div>{stats.rockyWld ? `${stats.rockyWld.w} / ${stats.rockyWld.l} / ${stats.rockyWld.d}` : "0 / 0 / 0"}</div>
          <div>{games === 0 ? 0 : Math.round(((stats.rockyWins ?? 0) / games) * 100)}%</div>
          <div className="stats__stat stats__average-score-diff">Average Score Difference</div>
          <div>vs {robots[0].name}:</div>
          <div>
            {robbieScoreDiff < 0 ? "-" : "+"}
            {Math.abs(robbieScoreDiff)}
          </div>
          <div />
          <div>vs {robots[1].name}:</div>
          <div>
            {rosieScoreDiff < 0 ? "-" : "+"}
            {Math.abs(rosieScoreDiff)}
          </div>
          <div />
          <div>vs {robots[2].name}:</div>
          <div>
            {rockyScoreDiff < 0 ? "-" : "+"}
            {Math.abs(rockyScoreDiff)}
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default Stats;
