import React, { useState, useEffect } from "react";
import Board from "./Board.js";
import NextCard from "./NextCard.js";
import Score from "./Score.js";
import {
  shuffle,
  handBoard,
  scoreBoard,
  calculateStats,
  SCORE_UK,
  SCORE_US,
  initialCalculateDailyStreak,
  initialCalulateRobotBeats,
} from "./pokerPatienceUtils.js";
import { robotsUk, robotsUs } from "./robots.js";
import "./PokerPatience.scss";
import Winner from "./Winner.js";
import Stats from "./Stats.js";
import Help from "./Help.js";
import About from "./About.js";
import confetti from "canvas-confetti";
import moment from "moment";
import Intro from "./Intro.js";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}
const runConfetti = (colors) => {
  const duration = 5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 10, spread: 360, ticks: 60, zIndex: 0, colors };

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.5), y: Math.random() - 0.2 } });
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.5, 0.9), y: Math.random() - 0.2 } });
  }, 250);
};

const continueStreak = (dateStr, dailyStreak) =>
  dailyStreak.date && moment(dateStr, "YYYY-M-D").diff(moment(dailyStreak.date, "YYYY-M-D"), "days") <= 1;

const useLocalStorageOrState = (store, key, defaultValue) => {
  const initialValue = store
    ? !localStorage || localStorage.getItem(key) === null
      ? defaultValue
      : JSON.parse(localStorage.getItem(key))
    : defaultValue;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (store) {
      localStorage?.setItem(key, JSON.stringify(value));
    }
  }, [value, key, store]);

  return [value, setValue];
};

const PokerPatience = ({ daily, setDaily, showIntro, setShowIntro }) => {
  const [cards, setCards] = useState([]);
  const [showRobotBoard, setShowRobotBoard] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [confettiMessage, setConfettiMessage] = useState(null);

  const [currentDate] = useState(new Date());
  const dateStr = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()}`;

  const [scoreSystem, setScoreSystem] = useLocalStorageOrState(true, "pokersquares.scoreSystem", "UK");
  const usUk = (us, uk) => (scoreSystem === "US" ? us : uk);

  const currentSeedUk = `${dateStr}`;
  const currentSeedUs = `${dateStr}-us`;
  const currentSeed = usUk(currentSeedUs, currentSeedUk);
  const robots = usUk(robotsUs, robotsUk);

  const [seedUk, setSeedUk] = useLocalStorageOrState(daily, "pokersquares.seed", currentSeedUk);
  const [seedUs, setSeedUs] = useLocalStorageOrState(daily, "pokersquares.seed.us", currentSeedUs);
  const [boardUk, setBoardUk] = useLocalStorageOrState(daily, "pokersquares.board", []);
  const [boardUs, setBoardUs] = useLocalStorageOrState(daily, "pokersquares.board.us", []);
  const [handsUk, setHandsUk] = useLocalStorageOrState(daily, "pokersquares.hands", { rows: [], columns: [] });
  const [handsUs, setHandsUs] = useLocalStorageOrState(daily, "pokersquares.hands.us", { rows: [], columns: [] });
  const [robbieBoardUk, setRobbieBoardUk] = useLocalStorageOrState(daily, "pokersquares.robbieBoard", []);
  const [robbieBoardUs, setRobbieBoardUs] = useLocalStorageOrState(daily, "pokersquares.robbieBoard.us", []);
  const [robbieHandsUk, setRobbieHandsUk] = useLocalStorageOrState(daily, "pokersquares.robbieHands", []);
  const [robbieHandsUs, setRobbieHandsUs] = useLocalStorageOrState(daily, "pokersquares.robbieHands.us", []);
  const [rosieBoardUk, setRosieBoardUk] = useLocalStorageOrState(daily, "pokersquares.rosieBoard", []);
  const [rosieBoardUs, setRosieBoardUs] = useLocalStorageOrState(daily, "pokersquares.rosieBoard.us", []);
  const [rosieHandsUk, setRosieHandsUk] = useLocalStorageOrState(daily, "pokersquares.rosieHands", []);
  const [rosieHandsUs, setRosieHandsUs] = useLocalStorageOrState(daily, "pokersquares.rosieHands.us", []);
  const [rockyBoardUk, setRockyBoardUk] = useLocalStorageOrState(daily, "pokersquares.rockyBoard", []);
  const [rockyBoardUs, setRockyBoardUs] = useLocalStorageOrState(daily, "pokersquares.rockyBoard.us", []);
  const [rockyHandsUk, setRockyHandsUk] = useLocalStorageOrState(daily, "pokersquares.rockyHands", []);
  const [rockyHandsUs, setRockyHandsUs] = useLocalStorageOrState(daily, "pokersquares.rockyHands.us", []);
  const [turnUk, setTurnUk] = useLocalStorageOrState(daily, "pokersquares.turn", 0);
  const [turnUs, setTurnUs] = useLocalStorageOrState(daily, "pokersquares.turn.us", 0);

  const [scoreUk, setScoreUk] = useLocalStorageOrState(daily, "pokersquares.score", 0);
  const [scoreUs, setScoreUs] = useLocalStorageOrState(daily, "pokersquares.score.us", 0);
  const [robbieScoreUk, setRobbieScoreUk] = useLocalStorageOrState(daily, "pokersquares.robbieScore", 0);
  const [robbieScoreUs, setRobbieScoreUs] = useLocalStorageOrState(daily, "pokersquares.robbieScore.us", 0);
  const [rosieScoreUk, setRosieScoreUk] = useLocalStorageOrState(daily, "pokersquares.rosieScore", 0);
  const [rosieScoreUs, setRosieScoreUs] = useLocalStorageOrState(daily, "pokersquares.rosieScore.us", 0);
  const [rockyScoreUk, setRockyScoreUk] = useLocalStorageOrState(daily, "pokersquares.rockyScore", 0);
  const [rockyScoreUs, setRockyScoreUs] = useLocalStorageOrState(daily, "pokersquares.rockyScore.us", 0);

  const [dailyStreakUk, setDailyStreakUk] = useLocalStorageOrState(true, "pokersquares.dailyStreak", {});
  const [dailyStreakUs, setDailyStreakUs] = useLocalStorageOrState(true, "pokersquares.dailyStreak.us", {});
  const [dailyStatsUk, setDailyStatsUk] = useLocalStorageOrState(true, "pokersquares.stats", {});
  const [dailyStatsUs, setDailyStatsUs] = useLocalStorageOrState(true, "pokersquares.stats.us", {});
  const [unlimitedStatsUk, setUnlimitedStatsUk] = useLocalStorageOrState(true, "pokersquares.unlimited.stats", {});
  const [unlimitedStatsUs, setUnlimitedStatsUs] = useLocalStorageOrState(true, "pokersquares.unlimited.stats.us", {});
  const [dailyGameHistoryUk, setDailyGameHistoryUk] = useLocalStorageOrState(true, "pokersquares.gamesHistory", []);
  const [dailyGameHistoryUs, setDailyGameHistoryUs] = useLocalStorageOrState(true, "pokersquares.gamesHistory.us", []);
  const [unlimitedGameHistoryUk, setUnlimitedGameHistoryUk] = useLocalStorageOrState(
    true,
    "pokersquares.unlimited.gamesHistory",
    []
  );
  const [unlimitedGameHistoryUs, setUnlimitedGameHistoryUs] = useLocalStorageOrState(
    true,
    "pokersquares.unlimited.gamesHistory.us",
    []
  );
  const [backup, setBackup] = useLocalStorageOrState(true, "pokersquares.backup", {});

  const seed = usUk(seedUs, seedUk);
  const setSeed = usUk(setSeedUs, setSeedUk);
  const board = usUk(boardUs, boardUk);
  const setBoard = usUk(setBoardUs, setBoardUk);
  const hands = usUk(handsUs, handsUk);
  const setHands = usUk(setHandsUs, setHandsUk);

  const robbieBoard = usUk(robbieBoardUs, robbieBoardUk);
  const setRobbieBoard = usUk(setRobbieBoardUs, setRobbieBoardUk);
  const robbieHands = usUk(robbieHandsUs, robbieHandsUk);
  const setRobbieHands = usUk(setRobbieHandsUs, setRobbieHandsUk);
  const rosieBoard = usUk(rosieBoardUs, rosieBoardUk);
  const setRosieBoard = usUk(setRosieBoardUs, setRosieBoardUk);
  const rosieHands = usUk(rosieHandsUs, rosieHandsUk);
  const setRosieHands = usUk(setRosieHandsUs, setRosieHandsUk);
  const rockyBoard = usUk(rockyBoardUs, rockyBoardUk);
  const setRockyBoard = usUk(setRockyBoardUs, setRockyBoardUk);
  const rockyHands = usUk(rockyHandsUs, rockyHandsUk);
  const setRockyHands = usUk(setRockyHandsUs, setRockyHandsUk);

  const turn = usUk(turnUs, turnUk);
  const setTurn = usUk(setTurnUs, setTurnUk);
  const scoring = usUk(SCORE_US, SCORE_UK);
  const score = usUk(scoreUs, scoreUk);
  const setScore = usUk(setScoreUs, setScoreUk);
  const robbieScore = usUk(robbieScoreUs, robbieScoreUk);
  const setRobbieScore = usUk(setRobbieScoreUs, setRobbieScoreUk);
  const rosieScore = usUk(rosieScoreUs, rosieScoreUk);
  const setRosieScore = usUk(setRosieScoreUs, setRosieScoreUk);
  const rockyScore = usUk(rockyScoreUs, rockyScoreUk);
  const setRockyScore = usUk(setRockyScoreUs, setRockyScoreUk);

  const dailyStreak = usUk(dailyStreakUs, dailyStreakUk);
  const setDailyStreak = usUk(setDailyStreakUs, setDailyStreakUk);
  const dailyStats = usUk(dailyStatsUs, dailyStatsUk);
  const setDailyStats = usUk(setDailyStatsUs, setDailyStatsUk);
  const unlimitedStats = usUk(unlimitedStatsUs, unlimitedStatsUk);
  const setUnlimitedStats = usUk(setUnlimitedStatsUs, setUnlimitedStatsUk);
  const dailyGameHistory = usUk(dailyGameHistoryUs, dailyGameHistoryUk);
  const setDailyGameHistory = usUk(setDailyGameHistoryUs, setDailyGameHistoryUk);
  const unlimitedGameHistory = usUk(unlimitedGameHistoryUs, unlimitedGameHistoryUk);
  const setUnlimitedGameHistory = usUk(setUnlimitedGameHistoryUs, setUnlimitedGameHistoryUk);

  useEffect(() => {
    if (!daily) {
      setSeed((Math.random() * 1000000000).toFixed(0));
    }
  }, [daily, setSeed]);

  useEffect(() => {
    let newBackup = backup;

    if (dailyStatsUk.version < 3 && scoreSystem === "UK") {
      setDailyStatsUk(initialCalulateRobotBeats(dailyStatsUk, dailyGameHistoryUk));
      newBackup = {
        ...newBackup,
        dailyStatsUs,
        dailyGameHistoryUs,
      };
      setBackup(newBackup);
      setDailyStatsUs({});
      setDailyGameHistoryUs([]);
    }
    if (dailyStatsUs.version < 3 && scoreSystem === "US") {
      setDailyStatsUs(initialCalulateRobotBeats(dailyStatsUs, dailyGameHistoryUs));
      newBackup = {
        ...newBackup,
        dailyStatsUk,
        dailyGameHistoryUk,
      };
      setBackup(newBackup);
      setDailyStatsUk({});
      setDailyGameHistoryUk([]);
    }
    if (unlimitedStatsUk.version < 3 && scoreSystem === "UK") {
      setUnlimitedStatsUk(initialCalulateRobotBeats(unlimitedStatsUk, unlimitedGameHistoryUk));
      newBackup = {
        ...newBackup,
        unlimitedStatsUs,
        unlimitedGameHistoryUs,
      };
      setBackup(newBackup);
      setUnlimitedStatsUs({});
      setUnlimitedGameHistoryUs([]);
    }
    if (unlimitedStatsUs.version < 3 && scoreSystem === "US") {
      setUnlimitedStatsUs(initialCalulateRobotBeats(unlimitedStatsUs, unlimitedGameHistoryUs));
      newBackup = {
        ...newBackup,
        unlimitedStatsUk,
        unlimitedGameHistoryUk,
      };
      setBackup(newBackup);
      setUnlimitedStatsUk({});
      setUnlimitedGameHistoryUk([]);
    }

    if (!daily || currentSeed !== seed) {
      setBoard([]);
      setHands({ rows: [], columns: [] });
      setRobbieBoard([]);
      setRosieBoard([]);
      setRockyBoard([]);

      setTurn(0);
      setScore(0);
      setRobbieScore(0);
      setRosieScore(0);
      setRockyScore(0);
    }

    if (daily) {
      if (!Object.keys(dailyStreak).length) {
        setDailyStreak(initialCalculateDailyStreak(dateStr, dailyGameHistory));
      }

      setSeed(currentSeed);
      setCards(shuffle(currentSeed, [...Array(52).keys()]));
    } else {
      setCards(shuffle(seed, [...Array(52).keys()]));
    }
  }, [seed, currentSeed, daily]);

  const handleSquareClick = (squareIndex) => {
    if (turn >= 25) {
      return;
    }

    setConfettiMessage(null);

    const newBoard = [...board];
    newBoard[squareIndex] = cards[turn];
    setBoard(newBoard);
    setHands(handBoard(newBoard));

    const newScore = scoreBoard(newBoard, scoring);
    setScore(newScore);

    const newRobbieBoard = robots[0].play(robbieBoard, cards[turn], turn);
    const newRobbieScore = scoreBoard(newRobbieBoard, scoring);
    setRobbieBoard(newRobbieBoard);
    setRobbieScore(newRobbieScore);
    setRobbieHands(handBoard(newRobbieBoard));

    const newRosieBoard = robots[1].play(rosieBoard, cards[turn], turn);
    const newRosieScore = scoreBoard(newRosieBoard, scoring);
    setRosieBoard(newRosieBoard);
    setRosieScore(newRosieScore);
    setRosieHands(handBoard(newRosieBoard));

    const newRockyBoard = robots[2].play(rockyBoard, cards[turn], turn);
    const newRockyScore = scoreBoard(newRockyBoard, scoring);
    setRockyBoard(newRockyBoard);
    setRockyScore(newRockyScore);
    setRockyHands(handBoard(newRockyBoard));

    if (turn === 24) {
      const todayGame = {
        date: dateStr,
        score: newScore,
        robbieScore: newRobbieScore,
        rosieScore: newRosieScore,
        rockyScore: newRockyScore,
      };

      if (daily) {
        setDailyGameHistory([...dailyGameHistory, todayGame]);
      } else {
        setUnlimitedGameHistory([...unlimitedGameHistory, todayGame]);
      }

      let stats;
      let newMaxStreak = false;

      if (daily) {
        stats = calculateStats(dailyStats, newScore, newRobbieScore, newRosieScore, newRockyScore);
        setDailyStats(stats);

        const contStreak = continueStreak(dateStr, dailyStreak);
        const currentStreak = contStreak ? (dailyStreak.current ?? 0) + 1 : 1;
        newMaxStreak = contStreak && (currentStreak > dailyStreak.max) && currentStreak > 5;

        setDailyStreak({
          current: currentStreak,
          max: currentStreak > dailyStreak.max ? currentStreak : dailyStreak.max,
          date: dateStr,
        });
      } else {
        stats = calculateStats(unlimitedStats, newScore, newRobbieScore, newRosieScore, newRockyScore);
        setUnlimitedStats(stats);
      }

      const score = newScore;

      // eslint-disable-next-line no-undef
      gtag("event", "post_score", {
        score,
        level: stats.games,
        character: `${daily ? "Daily" : "Unlimited"} ${scoreSystem}`,
      });

      if (stats.games > 1) {
        if (stats.newHighScore) {
          setConfettiMessage(`New high score!`);
          runConfetti();
        } else if (stats.newAllStreak) {
          setConfettiMessage(`Record winning streak versus all robots (${stats.maxAllStreak})!`);
          runConfetti();
        } else if (stats.newRobbieStreak || stats.newRosieStreak || stats.newRockyStreak) {
          const streaks = [];

          if (stats.newRobbieStreak) {
            streaks.push(`${robots[0].name} (${stats.maxRobbieStreak})`);
          }

          if (stats.newRosieStreak) {
            streaks.push(`${robots[1].name} (${stats.maxRosieStreak})`);
          }

          if (stats.newRockyStreak) {
            streaks.push(`${robots[2].name} (${stats.maxRockyStreak})`);
          }

          setConfettiMessage(`Record winning streak versus ${streaks.join(" and ")}!`);
          runConfetti();
        } else if (newMaxStreak) {
          setConfettiMessage(`New daily streak record!`);
          runConfetti();
        }
      }
    }

    setTurn(turn + 1);
  };

  const showRobbieBoard = () => setShowRobotBoard({ board: robbieBoard, hands: robbieHands });
  const hideRobbieBoard = () => setShowRobotBoard(null);
  const showRosieBoard = () => setShowRobotBoard({ board: rosieBoard, hands: rosieHands });
  const hideRosieBoard = () => setShowRobotBoard(null);
  const showRockyBoard = () => setShowRobotBoard({ board: rockyBoard, hands: rockyHands });
  const hideRockyBoard = () => setShowRobotBoard(null);
  const showDaily = () => {
    setDaily(true);
    setConfettiMessage(null);
  };
  const showUnlimited = () => {
    setDaily(false);
    setSeed((Math.random() * 1000000000).toFixed(0));
    setConfettiMessage(null);
  };
  const playNextUnlimited = () => {
    setSeed((Math.random() * 1000000000).toFixed(0));
    setConfettiMessage(null);
  };

  const messages = [];
  messages[0] = (
    <span>
      <span>Select any free square to start or </span>
      <i className="icon icon-help-circled" onClick={() => setShowHelp(true)}>
        &#xe801;
      </i>
      <span> for instructions</span>
    </span>
  );
  messages[1] = <span>Score points by making poker hands (pair, flush, straight etc.)</span>;
  messages[2] = <span>Both horizontal and vertical hands score</span>;
  messages[3] = (
    <span>
      <span>Using {scoreSystem === "US" ? "American 🇺🇸" : "English 🏴󠁧󠁢󠁥󠁮󠁧󠁿"} scoring - select </span>
      <i className="icon icon-cog" onClick={() => setShowAbout(true)}>
        &#xe802;
      </i>
      <span> to change</span>
    </span>
  );
  messages[4] = <span>Try to beat the robots - they play the same cards</span>;
  messages[5] = <span>Press a robot's score to view their board</span>;
  messages[6] = (
    <span>
      <span>Select </span>
      <i className="icon icon-chart-bar" onClick={() => setShowStats(true)}>
        &#xe800;
      </i>
      <span> to view your stats</span>
    </span>
  );
  messages[7] = <span>Play PokerSquares Daily Challenge - everyone plays the same cards</span>;
  messages[8] = <span>Play PokerSquares Unlimited for unlimited games</span>;

  if (daily) {
    messages[25] = (
      <span>
        <span>Play </span>
        <span onClick={showUnlimited} className="poker-patience-unlimited">
          PokerSquares Unlimited
        </span>
        <span> for unlimited games</span>
      </span>
    );
  }

  const showDialog = showIntro || showStats || showHelp || showAbout;
  const showMessage =
    !showDialog &&
    !showRobotBoard &&
    (dailyGameHistory.length === 0 || (dailyGameHistory.length === 1 && turn === 25)) &&
    (unlimitedGameHistory.length === 0 || (unlimitedGameHistory.length === 1 && turn === 25)) &&
    messages[turn];
  const showLostStreak =
    !showDialog &&
    !showRobotBoard &&
    !showMessage &&
    daily &&
    turn === 0 &&
    dailyStreak.current > 1 &&
    !continueStreak(dateStr, dailyStreak);
  const showConfetti = !showMessage && !showDialog && !showLostStreak && confettiMessage;

  return (
    <div className={`poker-patience ${daily ? "poker-patience__daily" : "poker-patience__unlimited"}`}>
      {showIntro ? (
        <Intro
          daily={daily}
          onPlay={(scoreSystem) => {
            setScoreSystem(scoreSystem);
            setShowIntro(false);
          }}
          onUnlimited={showUnlimited}
          onDaily={showDaily}
        />
      ) : null}
      {showStats ? (
        <Stats
          stats={daily ? dailyStats : unlimitedStats}
          dailyStreak={dailyStreak}
          daily={daily}
          scoreSystem={scoreSystem}
          robots={robots}
          onClose={() => setShowStats(false)}
        />
      ) : null}
      {showHelp ? <Help scoring={scoring} onClose={() => setShowHelp(false)} /> : null}
      {showAbout ? (
        <About scoring={scoreSystem} onChangeScoring={setScoreSystem} onClose={() => setShowAbout(false)} />
      ) : null}
      {showMessage ? (
        <div className="poker-patience__message">
          <div>{messages[turn]}</div>
        </div>
      ) : null}
      {showLostStreak ? (
        <div className="poker-patience__message">
          <div>You have lost your daily game streak</div>
        </div>
      ) : null}
      {showConfetti ? (
        <div className="poker-patience__message">
          <div>{confettiMessage}</div>
        </div>
      ) : null}
      <div className="poker-patience-header">
        <div className="poker-patience-header__about">
          {!showDialog ? (
            <i className="icon icon-cog" onClick={() => setShowAbout(true)}>
              &#xe802;
            </i>
          ) : null}
          {!showDialog ? (
            <span
              className="poker-patience-header__scoring"
              onClick={() => setScoreSystem(scoreSystem === "UK" ? "US" : "UK")}
            >
              {scoreSystem === "UK" ? "🇬🇧" : "🇺🇸"}
            </span>
          ) : null}
        </div>
        {daily ? (
          <h1
            className={`poker-patience__title poker-patience-daily${
              showIntro ? " poker-patience__title--overlay" : ""
            }`}
          >
            PokerSquares Daily Challenge
          </h1>
        ) : (
          <h1
            className={`poker-patience__title poker-patience-unlimited${
              showIntro ? " poker-patience__title--overlay" : ""
            }`}
          >
            <span>PokerSquares</span>
            <span className="poker-patience-unlimited__wide"> </span>
            <br className="poker-patience-unlimited__narrow" />
            <span>Unlimited</span>
          </h1>
        )}
        <div className="poker-patience-header__actions">
          {!showDialog ? (
            <i className="icon icon-chart-bar" onClick={() => setShowStats(true)}>
              &#xe800;
            </i>
          ) : null}
          {!showDialog ? (
            <i className="icon icon-help-circled" onClick={() => setShowHelp(true)}>
              &#xe801;
            </i>
          ) : null}
        </div>
      </div>
      <div className={`poker-patience__main${showDialog ? " poker-patience__main--overlay" : ""}`}>
        <div className="poker-patience__board">
          {showRobotBoard ? (
            <Board board={showRobotBoard.board} hands={showRobotBoard.hands} robot={true} />
          ) : (
            <Board board={board} hands={hands} onClick={handleSquareClick} />
          )}
        </div>
        <div className="poker-patience__info">
          {cards.length && turn < 25 ? <NextCard cardIndex={cards[turn]} ended={turn === 25} /> : null}
          {turn >= 25 ? (
            <Winner
              score={score}
              robbieScore={robbieScore}
              rosieScore={rosieScore}
              rockyScore={rockyScore}
              robots={robots}
              playAgain={!daily && playNextUnlimited}
            />
          ) : null}
          <div className="break" />
          <Score name="Your Score" score={score} robot={false} />
          <div className="break" />
          <div className="robot-scores">
            <Score
              name={`${robots[0].name} Robot`}
              score={robbieScore}
              robot={true}
              diff={robbieScore - score}
              onMouseDown={showRobbieBoard}
              onMouseUp={hideRobbieBoard}
            />
            <Score
              name={`${robots[1].name} Robot`}
              score={rosieScore}
              robot={true}
              diff={rosieScore - score}
              onMouseDown={showRosieBoard}
              onMouseUp={hideRosieBoard}
            />
            <Score
              name={`${robots[2].name} Robot`}
              score={rockyScore}
              robot={true}
              diff={rockyScore - score}
              onMouseDown={showRockyBoard}
              onMouseUp={hideRockyBoard}
            />
          </div>
          <div className="break" />
          {daily ? (
            <div className="poker-patience__play_unlimited" onClick={showUnlimited}>
              Play <span className="poker-patience-unlimited">PokerSquares Unlimited</span>
            </div>
          ) : (
            <div className="poker-patience__play_daily" onClick={showDaily}>
              Play <span className="poker-patience-daily">PokerSquares Daily Challenge</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PokerPatience;
