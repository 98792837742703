import Card from "./Card.js";
import "./NextCard.scss";

const NextCard = ({ cardIndex, ended }) => {
  return (
    <div className="next-card__container">
      <div className="next-card__title">Next Card</div>
      <div className="next-card">
        <div className="next-card__square">{ended ? null : <Card cardIndex={cardIndex} />}</div>
      </div>
    </div>
  );
};

export default NextCard;
