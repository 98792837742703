import { useState } from "react";
import Instructions from "./Instructions.js";
import "./Intro.scss";
import { SCORE_UK, SCORE_US } from "./pokerPatienceUtils.js";

const Intro = ({ daily, onPlay, onUnlimited, onDaily }) => {
  const [instructionScoring, setInstructionScoring] = useState(null);

  return (
    <div className="intro__container">
      <div>
        <div className="intro__title">
          <span>Welcome to PokerSquares {daily ? "Daily Challenge" : "Unlimited"}</span>
        </div>
        <div className="intro__play">
          <div className="intro__scoring">
            <span
              onClick={(event) => {
                onPlay("UK");
                event.stopPropagation();
              }}
            >
              Play - UK Scoring 🇬🇧
            </span>
          </div>
          <div className="intro__scoring">
            <span
              onClick={(event) => {
                onPlay("US");
                event.stopPropagation();
              }}
            >
              Play - US Scoring 🇺🇸
            </span>
          </div>
          {daily ? (
            <div className="intro__unlimited">
              <span
                onClick={(event) => {
                  onUnlimited();
                  event.stopPropagation();
                }}
              >
                Unlimited
              </span>
            </div>
          ) : (
            <div className="intro__daily">
              <span
                onClick={(event) => {
                  onDaily();
                  event.stopPropagation();
                }}
              >
                Daily Challenge
              </span>
            </div>
          )}
        </div>
        <div className="intro__instructions">
          <div>Instructions:</div>
          <div className={`intro__show_instructions${instructionScoring === SCORE_UK ? " active" : ""}`}>
            <span
              onClick={(event) => {
                setInstructionScoring(instructionScoring === SCORE_UK ? null : SCORE_UK);
                event.stopPropagation();
              }}
            >
              UK 🇬🇧
            </span>
          </div>
          <div className={`intro__show_instructions${instructionScoring === SCORE_US ? " active" : ""}`}>
            <span
              onClick={(event) => {
                setInstructionScoring(instructionScoring === SCORE_US ? null : SCORE_US);
                event.stopPropagation();
              }}
            >
              US 🇺🇸
            </span>
          </div>
        </div>
        {instructionScoring && <Instructions scoring={instructionScoring} />}
      </div>
    </div>
  );
};

export default Intro;
