import { cardNumber, cardSuit } from "./pokerPatienceUtils.js";
import "./Card.scss";

const Card = ({ cardIndex }) => {
  const number = cardNumber(cardIndex);
  const suit = cardSuit(cardIndex);

  return (
    <div className={`card card__${suit.css}`}>
      <div className="card_inner">
        <span className="card__number">{number}</span>
        <span className={"card__suit"}>{suit.char}</span>
      </div>
    </div>
  );
};

export default Card;
