import { useMemo } from "react";
import Card from "./Card.js";
import "./Help.scss";

const Instructions = ({ scoring }) => {
  const hands = useMemo(() =>
    [
      {
        score: scoring.ROYAL_FLUSH + 0.1,
        html: (
          <div key="ROYAL_FLUSH" className="help__hand">
            <div className="help__hand-name">Royal Flush</div>
            <div className="help__hand-score">{scoring.ROYAL_FLUSH}</div>
            <div className="help__hand-description">Straight ten to ace, all the same suit, in any order</div>
            <div className="help__hand-cards">
              <Card cardIndex={25} />
              <Card cardIndex={23} />
              <Card cardIndex={22} />
              <Card cardIndex={24} />
              <Card cardIndex={13} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.STRAIGHT_FLUSH,
        html: (
          <div key="STRAIGHT_FLUSH" className="help__hand">
            <div className="help__hand-name">Straight Flush</div>
            <div className="help__hand-score">{scoring.STRAIGHT_FLUSH}</div>
            <div className="help__hand-description">
              Straight (five cards), all the same suit, in any order. Ace can be high or low, but straights cannot wrap
              around.
            </div>
            <div className="help__hand-cards">
              <Card cardIndex={35} />
              <Card cardIndex={34} />
              <Card cardIndex={33} />
              <Card cardIndex={32} />
              <Card cardIndex={36} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.FOUR_OF_KIND,
        html: (
          <div key="FOUR_OF_KIND" className="help__hand">
            <div className="help__hand-name">Four-of-a-Kind</div>
            <div className="help__hand-score">{scoring.FOUR_OF_KIND}</div>
            <div className="help__hand-description">Four cards with the same value, e.g. four aces</div>
            <div className="help__hand-cards">
              <Card cardIndex={0} />
              <Card cardIndex={13} />
              <Card cardIndex={26} />
              <Card cardIndex={39} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.STRAIGHT,
        html: (
          <div key="STRAIGHT" className="help__hand">
            <div className="help__hand-name">Straight</div>
            <div className="help__hand-score">{scoring.STRAIGHT}</div>
            <div className="help__hand-description">
              Straight (five cards), any suit, in any order. Ace can be high or low, but straights cannot wrap around.
            </div>
            <div className="help__hand-cards">
              <Card cardIndex={32} />
              <Card cardIndex={3} />
              <Card cardIndex={17} />
              <Card cardIndex={46} />
              <Card cardIndex={5} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.FULL_HOUSE,
        html: (
          <div key="FULL_HOUSE" className="help__hand">
            <div className="help__hand-name">Full House</div>
            <div className="help__hand-score">{scoring.FULL_HOUSE}</div>
            <div className="help__hand-description">
              Three-of-a-kind with one value and pair with another value, e.g. three kings and two 4s
            </div>
            <div className="help__hand-cards">
              <Card cardIndex={3} />
              <Card cardIndex={12} />
              <Card cardIndex={12} />
              <Card cardIndex={38} />
              <Card cardIndex={29} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.THREE_OF_KIND,
        html: (
          <div key="THREE_OF_KIND" className="help__hand">
            <div className="help__hand-name">Three-of-a-Kind</div>
            <div className="help__hand-score">{scoring.THREE_OF_KIND}</div>
            <div className="help__hand-description">Three cards with the same value, e.g. three 6s</div>
            <div className="help__hand-cards">
              <Card cardIndex={31} />
              <Card cardIndex={5} />
              <Card cardIndex={18} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.FLUSH,
        html: (
          <div key="FLUSH" className="help__hand">
            <div className="help__hand-name">Flush</div>
            <div className="help__hand-score">{scoring.FLUSH}</div>
            <div className="help__hand-description">All five cards with the same suit</div>
            <div className="help__hand-cards">
              <Card cardIndex={17} />
              <Card cardIndex={23} />
              <Card cardIndex={19} />
              <Card cardIndex={21} />
              <Card cardIndex={13} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.TWO_PAIR,
        html: (
          <div key="TWO_PAIR" className="help__hand">
            <div className="help__hand-name">Two Pair</div>
            <div className="help__hand-score">{scoring.TWO_PAIR}</div>
            <div className="help__hand-description">
              Pair with one value and pair with another value, e.g. two jacks and two 5s
            </div>
            <div className="help__hand-cards">
              <Card cardIndex={23} />
              <Card cardIndex={30} />
              <Card cardIndex={4} />
              <Card cardIndex={10} />
            </div>
          </div>
        ),
      },
      {
        score: scoring.PAIR,
        html: (
          <div key="PAIR" className="help__hand">
            <div className="help__hand-name">Pair</div>
            <div className="help__hand-score">{scoring.PAIR}</div>
            <div className="help__hand-description">Two cards with the same value, e.g. two kings</div>
            <div className="help__hand-cards">
              <Card cardIndex={12} />
              <Card cardIndex={51} />
            </div>
          </div>
        ),
      },
    ].sort((a, b) => b.score - a.score),
    [scoring]
  );

  return (
    <div className="help">
      <div className="help__instructions">
        In poker squares you place 25 cards in a 5x5 grid, making poker hands both horizontally and vertically. Each
        hand has a score associated and the aim is to get the highest score possible counting both horizontal and
        vertical hands.
      </div>
      <div className="help__hand-header">The poker hands are:</div>
      {hands.map(hand => hand.html)}
    </div>
  );
};

export default Instructions;
