import { robot, generateRobot } from "./pokerPatienceRobot.js";

export const ROBBIE_ROBOT = [
  [
    117, 103, 68, 49, 6, 19, 77, 30, 32, 27, 46, 34, 107, 82, 7, 53, 39, 65, 30, 40, 5, 22, 101, 106, 111, 119, 105, 16,
    34, 56, 55, 52, 36, 48,
  ],
  [
    73, 67, 63, 41, 5, 83, 109, 105, 79, 20, 119, 116, 18, 40, 111, 114, 29, 118, 58, 13, 23, 37, 58, 31, 12, 115, 18,
    5, 31, 10, 37, 5, 50, 93,
  ],
  [
    109, 119, 97, 75, 47, 7, 100, 61, 48, 7, 94, 71, 56, 40, 38, 25, 20, 104, 101, 92, 72, 43, 106, 92, 85, 103, 102,
    26, 99, 94, 55, 20, 80, 93,
  ],
  [
    62, 95, 92, 81, 61, 42, 101, 97, 83, 56, 111, 119, 112, 120, 120, 100, 80, 65, 85, 8, 117, 77, 86, 82, 74, 70, 53,
    68, 30, 95, 39, 55, 70, 93,
  ],
];

export const ROSIE_ROBOT = [
  [
    94, 88, 64, 69, 63, 93, 79, 4, 22, 6, 99, 27, 20, 91, 37, 8, 64, 67, 94, 103, 118, 118, 74, 27, 38, 44, 42, 18, 50,
    117, 54, 70, 37, 86,
  ],
  [
    65, 89, 68, 24, 23, 31, 114, 88, 47, 8, 108, 108, 77, 105, 118, 95, 45, 14, 62, 19, 93, 33, 69, 18, 49, 44, 4, 15,
    1, 52, 47, 56, 76, 112,
  ],
  [
    105, 114, 86, 53, 30, 7, 96, 46, 27, 16, 10, 113, 75, 80, 98, 95, 16, 117, 100, 93, 102, 112, 103, 83, 30, 41, 83,
    1, 69, 62, 94, 76, 59, 46,
  ],
  [
    16, 59, 81, 64, 41, 11, 88, 77, 48, 20, 85, 87, 53, 84, 107, 83, 38, 114, 58, 72, 36, 21, 86, 56, 29, 15, 3, 3, 35,
    79, 78, 19, 101, 63,
  ],
];

export const ROCKY_ROBOT = [
  [
    58, 48, 27, 49, 40, 60, 12, 9, 44, 74, 1, 114, 7, 27, 54, 4, 77, 38, 17, 6, 41, 5, 65, 104, 105, 112, 70, 68, 80,
    89, 4, 2, 52, 115,
  ],
  [
    116, 108, 101, 48, 33, 31, 118, 117, 96, 14, 102, 120, 24, 55, 88, 45, 17, 75, 85, 114, 101, 30, 28, 61, 45, 91, 37,
    59, 113, 70, 79, 64, 39, 9,
  ],
  [
    13, 103, 60, 31, 1, 19, 94, 42, 15, 20, 105, 65, 81, 43, 112, 60, 36, 4, 13, 42, 80, 106, 62, 48, 62, 105, 6, 33,
    86, 61, 57, 16, 30, 20,
  ],
  [
    83, 32, 67, 46, 17, 6, 81, 82, 53, 13, 92, 120, 95, 35, 35, 106, 70, 47, 73, 30, 99, 77, 15, 16, 84, 36, 43, 111,
    30, 114, 37, 39, 111, 98,
  ],
];

export const RANDY_ROBOT = [
  [
    113, 90, 64, 33, 14, 13, 54, 2, 31, 63, 113, 120, 39, 0, 111, 91, 112, 118, 72, 87, 116, 120, 38, 27, 2, 88, 8, 20,
    12, 16, 100, 67, 112, 16,
  ],
  [
    53, 61, 60, 21, 27, 37, 113, 89, 30, 27, 120, 110, 76, 115, 99, 113, 74, 4, 48, 37, 76, 18, 62, 27, 101, 118, 24,
    33, 114, 31, 21, 6, 78, 5,
  ],
  [
    31, 114, 76, 77, 42, 11, 45, 60, 20, 5, 100, 39, 37, 41, 42, 55, 104, 101, 110, 94, 100, 116, 79, 16, 69, 75, 17,
    67, 53, 72, 21, 29, 2, 73,
  ],
  [
    8, 23, 109, 62, 41, 24, 77, 108, 84, 48, 86, 120, 71, 30, 120, 107, 81, 107, 54, 113, 15, 68, 82, 80, 23, 42, 86,
    61, 9, 113, 116, 105, 15, 33,
  ],
];

export const robotsUk = [
  {
    name: "Robbie",
    dna: ROBBIE_ROBOT,
    play: robot(generateRobot(ROBBIE_ROBOT))
  },
  {
    name: "Rosie",
    dna: ROSIE_ROBOT,
    play: robot(generateRobot(ROSIE_ROBOT))
  },
  {
    name: "Rocky",
    dna: ROCKY_ROBOT,
    play: robot(generateRobot(ROCKY_ROBOT))
  },
];

export const robotsUs = [
  {
    name: "Robbie",
    dna: ROBBIE_ROBOT,
    play: robot(generateRobot(ROBBIE_ROBOT))
  },
  {
    name: "Rosie",
    dna: ROSIE_ROBOT,
    play: robot(generateRobot(ROSIE_ROBOT))
  },
  {
    name: "Randy",
    dna: RANDY_ROBOT,
    play: robot(generateRobot(RANDY_ROBOT))
  },
];

export const DEFAULT_ROBOT = {
  // NO_PAIR
  NO_PAIR_5: {
    value: 5,
    decay: 0,
  },
  NO_PAIR_4: {
    value: 4,
    decay: 0,
  },
  NO_PAIR_3: {
    value: 2,
    decay: 0,
  },
  NO_PAIR_2: {
    value: 0,
    decay: 0,
  },
  NO_PAIR_1: {
    value: 2,
    decay: 0,
  },
  NO_PAIR_0: {
    value: 4,
    decay: 0,
  },
  // PAIR
  PAIR_3: {
    value: 20,
    decay: 0,
  },
  PAIR_2: {
    value: 15,
    decay: 0,
  },
  PAIR_1: {
    value: 10,
    decay: 0,
  },
  PAIR_0: {
    value: 6,
    decay: 0,
  },
  // THREE_OF_KIND
  THREE_OF_KIND_2: {
    value: 60,
    decay: 0,
  },
  THREE_OF_KIND_1: {
    value: 53,
    decay: 0,
  },
  THREE_OF_KIND_0: {
    value: 41,
    decay: 0,
  },
  // FOUR_OF_KIND
  FOUR_OF_KIND_1: {
    value: 90,
    decay: 0,
  },
  FOUR_OF_KIND_0: {
    value: 92,
    decay: 0,
  },
  // TWO_PAIR
  TWO_PAIR_1: {
    value: 48,
    decay: 0,
  },
  TWO_PAIR_0: {
    value: 38,
    decay: 0,
  },
  // FULL_HOUSE
  FULL_HOUSE_0: {
    value: 80,
    decay: 0,
  },
  // FLUSH
  FLUSH_3: {
    value: 6,
    decay: 0,
  },
  FLUSH_2: {
    value: 7,
    decay: 0,
  },
  FLUSH_1: {
    value: 12,
    decay: 0,
  },
  FLUSH_0: {
    value: 16,
    decay: 0,
  },
  // IN_STRAIGHT
  IN_STRAIGHT_3: {
    value: 8,
    decay: 0,
  },
  IN_STRAIGHT_2: {
    value: 16,
    decay: 0,
  },
  IN_STRAIGHT_1: {
    value: 32,
    decay: 0,
  },
  IN_STRAIGHT_0: {
    value: 80,
    decay: 0,
  },
  // OUT_STRAIGHT
  OUT_STRAIGHT_3: {
    value: 12,
    decay: 0,
  },
  OUT_STRAIGHT_2: {
    value: 22,
    decay: 0,
  },
  OUT_STRAIGHT_1: {
    value: 40,
    decay: 0,
  },
  OUT_STRAIGHT_0: {
    value: 80,
    decay: 0,
  },
  // FLUSH_STRAIGHT
  FLUSH_STRAIGHT_3: {
    value: 14,
    decay: 0,
  },
  FLUSH_STRAIGHT_2: {
    value: 25,
    decay: 0,
  },
  FLUSH_STRAIGHT_1: {
    value: 43,
    decay: 0,
  },
  FLUSH_STRAIGHT_0: {
    value: 110,
    decay: 0,
  },
};
