import React, { useState } from "react";
import PokerPatience from "./PokerPatience.js";

const PokerPatienceDailyUnlimited = () => {
  const [daily, setDaily] = useState(true);
  const [showIntro, setShowIntro] = useState(true);

  return daily ? (
    <PokerPatience key="daily" daily={true} setDaily={setDaily} showIntro={showIntro} setShowIntro={setShowIntro}/>
  ) : (
    <PokerPatience key="unlimited" daily={false} setDaily={setDaily} showIntro={showIntro} setShowIntro={setShowIntro}/>
  );
};

export default PokerPatienceDailyUnlimited;
