import "./Winner.scss";

const Winner = ({ score, robbieScore, rosieScore, rockyScore, robots, playAgain }) => {
  let message;
  let beat = [];
  let lost = [];

  if (score > robbieScore) {
    beat.push(robots[0].name);
  } else if (score < robbieScore) {
    lost.push(robots[0].name);
  }

  if (score > rosieScore) {
    beat.push(robots[1].name);
  } else if (score < rosieScore) {
    lost.push(robots[1].name);
  }

  if (score > rockyScore) {
    beat.push(robots[2].name);
  } else if (score < robbieScore) {
    lost.push(robots[2].name);
  }

  if (beat.length === 3) {
    message = "Congratulations, you beat all the robots!";
  } else if (lost.length === 3) {
    message = "Bad luck, all the robots beat you!";
  } else if (beat.length === 2) {
    message = `Well done, you beat ${beat[0]} and ${beat[1]}`;
  } else if (beat.length === 1) {
    message = `Good effort, you beat ${beat[0]}`;
  } else {
    message = "Bad luck, at least you didn't lose to all the robots!";
  }

  return (
    <div className="winner">
      <div>{message}</div>
      {playAgain ? <div className="winner__play-again" onClick={playAgain}>Play again?</div> : null}
    </div>
  );
};

export default Winner;
