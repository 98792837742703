import "./Help.scss";
import Instructions from "./Instructions.js";

const Help = ({ onClose, scoring }) => {
  return (
    <div className="help__container" onClick={onClose}>
      <Instructions scoring={scoring}/>
    </div>
  );
};

export default Help;
