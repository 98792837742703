import Card from "./Card.js";
import "./Board.scss";
import { boardColumnIndex, boardRowIndex } from "./pokerPatienceUtils.js";
import { Fragment } from "react";

const Square = ({ index, cardIndex, rowHand, columnHand, robot, onClick }) => {
  return (
    <div className="board__square">
      <div className="board__hand-container">
        {rowHand ? <div className="board__hand-background" /> : null}
        {columnHand ? <div className="board__hand-background" /> : null}
        {rowHand ? (
          <Fragment>
            <div className={`board__hand board__row-hand board__hand-${rowHand.toLowerCase().replace(" ", "")}`}>
              <div>
                <div />
                <div className="board__hand-label">{rowHand.indexOf("_") >= 0 ? rowHand.split("_")[0] : rowHand}</div>
                <div />
                <div />
                <div />
                <div className="board__hand-label">{rowHand.indexOf("_") >= 0 ? rowHand.split("_")[1] : rowHand}</div>
                <div />
              </div>
            </div>
          </Fragment>
        ) : null}
        {columnHand ? (
          <Fragment>
            <div className={`board__hand board__column-hand board__hand-${columnHand.toLowerCase().replace(" ", "")}`}>
              <div>
                <div />
                <div className="board__hand-label">
                  {columnHand.indexOf("_") >= 0 ? columnHand.split("_")[0] : columnHand}
                </div>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div className="board__hand-label">
                  {columnHand.indexOf("_") >= 0 ? columnHand.split("_")[1] : columnHand}
                </div>
                <div />
              </div>
            </div>
          </Fragment>
        ) : null}
      </div>
      {typeof cardIndex === "number" ? (
        <Card cardIndex={cardIndex} />
      ) : (
        <div className="board__empty_square" onClick={() => onClick(index)}>
          <span>{robot ? "Robot" : "Free"}</span>
        </div>
      )}
    </div>
  );
};

const Board = ({ board, hands, robot, onClick }) => {
  return (
    <div className="board">
      {[...Array(25).keys()].map((i) => {
        const rowHand = hands.rows?.[boardRowIndex(i)]?.[boardColumnIndex(i)];
        const columnHand = hands.columns?.[boardColumnIndex(i)]?.[boardRowIndex(i)];

        return (
          <Square
            key={i}
            index={i}
            cardIndex={board[i]}
            rowHand={rowHand}
            columnHand={columnHand}
            robot={robot}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default Board;
