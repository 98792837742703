import {
  countNumbers,
  countSuits,
  countFree,
  cardNumberIndex,
  boardRowIndex,
  boardColumnIndex,
  boardRow,
  boardColumn,
} from "./pokerPatienceUtils.js";

export const KEYS = [
  "NO_PAIR_5",
  "NO_PAIR_4",
  "NO_PAIR_3",
  "NO_PAIR_2",
  "NO_PAIR_1",
  "NO_PAIR_0",
  // PAIR
  "PAIR_3",
  "PAIR_2",
  "PAIR_1",
  "PAIR_0",
  // THREE_OF_KIND
  "THREE_OF_KIND_2",
  "THREE_OF_KIND_1",
  "THREE_OF_KIND_0",
  // FOUR_OF_KIND
  "FOUR_OF_KIND_1",
  "FOUR_OF_KIND_0",
  // TWO_PAIR
  "TWO_PAIR_1",
  "TWO_PAIR_0",
  // FULL_HOUSE
  "FULL_HOUSE_0",
  // FLUSH
  "FLUSH_3",
  "FLUSH_2",
  "FLUSH_1",
  "FLUSH_0",
  // IN_STRAIGHT
  "IN_STRAIGHT_3",
  "IN_STRAIGHT_2",
  "IN_STRAIGHT_1",
  "IN_STRAIGHT_0",
  // OUT_STRAIGHT
  "OUT_STRAIGHT_3",
  "OUT_STRAIGHT_2",
  "OUT_STRAIGHT_1",
  "OUT_STRAIGHT_0",
  // FLUSH_STRAIGHT
  "FLUSH_STRAIGHT_3",
  "FLUSH_STRAIGHT_2",
  "FLUSH_STRAIGHT_1",
  "FLUSH_STRAIGHT_0",
];

const LATES_GAMES_VALUES_TURN = 14;

const STRAIGHTS = {
  IN_STRAIGHT: "IN_STRAIGHT",
  OUT_STRAIGHT: "OUT_STRAIGHT",
};

export const generateRobot = (dna4) =>
  dna4.map((dna) => {
    const robot = {};
    dna.forEach((value, i) => (robot[KEYS[i]] = { value }));
    return robot;
  });

const straightType = (rowcol) => {
  if (rowcol.find((rc) => typeof rc !== "number")) {
    return null;
  }

  const sorted = rowcol
    .filter((rc) => typeof rc === "number")
    .map(cardNumberIndex)
    .sort((a, b) => a - b);

  for (let i = 0; i < sorted.length - 1; i++) {
    if (sorted[i] === sorted[i + 1]) {
      return null;
    }
  }

  if (sorted[0] === 0 && sorted[1] >= 9) {
    return STRAIGHTS.IN_STRAIGHT;
  } else if (sorted[0] === 0 && sorted[sorted.length - 1] <= 4) {
    return STRAIGHTS.IN_STRAIGHT;
  } else if (sorted[sorted.length - 1] - sorted[0] === sorted.length - 1) {
    return STRAIGHTS.OUT_STRAIGHT;
  } else if (sorted[sorted.length - 1] - sorted[0] <= 4) {
    return STRAIGHTS.IN_STRAIGHT;
  }

  return null;
};

const evaluateRowCol = (rowcol, robotValues, isColumn, turn) => {
  const numCounts = countNumbers(rowcol);
  const suitCounts = countSuits(rowcol);
  const freeCount = countFree(rowcol);

  const fourOfKind = numCounts.find((count) => count === 4);
  const threeOfKind = numCounts.find((count) => count === 3);
  const pairs = numCounts.filter((count) => count === 2).length;
  const flush = freeCount <= 3 && suitCounts.find((count) => count === 5 - freeCount);
  const straight = freeCount <= 3 && straightType(rowcol);

  let maxValue = -10000;

  if (fourOfKind) {
    maxValue = Math.max(maxValue, robotValues[`FOUR_OF_KIND_${freeCount}`].value);
  }
  if (threeOfKind && pairs === 1) {
    maxValue = Math.max(maxValue, robotValues[`FULL_HOUSE_${freeCount}`].value);
  }
  if (threeOfKind) {
    maxValue = Math.max(maxValue, robotValues[`THREE_OF_KIND_${freeCount}`].value);
  }
  if (pairs === 2) {
    maxValue = Math.max(maxValue, robotValues[`TWO_PAIR_${freeCount}`].value);
  }
  if (pairs === 1) {
    maxValue = Math.max(maxValue, robotValues[`PAIR_${freeCount}`].value);
  }

  if (flush) {
    maxValue = Math.max(maxValue, robotValues[`FLUSH_${freeCount}`].value);
  }

  if (straight) {
    maxValue = Math.max(maxValue, robotValues[`IN_STRAIGHT_${freeCount}`].value);
  }
  if (straight === STRAIGHTS.OUT_STRAIGHT) {
    maxValue = Math.max(maxValue, robotValues[`OUT_STRAIGHT_${freeCount}`].value);
  }
  if (straight && flush) {
    maxValue = Math.max(maxValue, robotValues[`FLUSH_STRAIGHT_${freeCount}`].value);
  }

  if (maxValue === -10000) {
    maxValue = Math.max(maxValue, robotValues[`NO_PAIR_${freeCount}`].value);
  }

  return maxValue;
};

export const robot = (robotValues) => {
  return (board, card, turn) => {
    let maxValue = null;
    let maxIndex;

    //   console.log();
    //   console.log('Turn', turn);

    const lateness = turn >= LATES_GAMES_VALUES_TURN ? 2 : 0;

    [...Array(25).keys()].forEach((i) => {
      if (typeof board[i] === "number") {
        return null;
      }

      const rowIndex = boardRowIndex(i);
      const columnIndex = boardColumnIndex(i);

      const row = [...boardRow(board, rowIndex)];
      const column = [...boardColumn(board, columnIndex)];

      const rowValue1 = evaluateRowCol(row, robotValues[lateness], false, turn);
      row[columnIndex] = card;
      const rowValue2 = evaluateRowCol(row, robotValues[lateness], false, turn);

      const columnValue1 = evaluateRowCol(column, robotValues[1 + lateness], true, turn);
      column[rowIndex] = card;
      const columnValue2 = evaluateRowCol(column, robotValues[1 + lateness], true, turn);

      const value = rowValue2 + columnValue2 - (rowValue1 + columnValue1);

      // console.log(columnIndex, rowIndex, '-', value, '-', columnValue1, columnValue2, rowValue1, rowValue2);

      if (maxValue === null || value > maxValue) {
        maxValue = value;
        maxIndex = i;
      }
    });

    const newBoard = [...board];
    newBoard[maxIndex] = card;
    return newBoard;
  };
};
