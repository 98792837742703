import { useState } from "react";
import "./Score.scss";

const Score = ({ name, score, robot, diff, onMouseDown, onMouseUp }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <div
      className={`score__container${robot ? " score__container-robot" : ""}${(robot && clicked) ? " score__container-robot--clicked" : ""}`}
      onMouseDown={() => {
        setClicked(true);
        onMouseDown && onMouseDown();
      }}
      onMouseUp={() => {
        setClicked(false);
        onMouseUp && onMouseUp();
      }}
      onTouchStart={() => {
        setClicked(true);
        onMouseDown && onMouseDown();
      }}
      onTouchEnd={() => {
        setClicked(false);
        onMouseUp && onMouseUp();
      }}
    >
      <div className="score__title">{name}</div>
      <div className="score">{score}</div>
      {typeof diff === "number" && diff !== 0 ? (
        <div className="score__diff">
          {diff < 0 ? "-" : diff > 0 ? "+" : ""}
          {Math.abs(diff)}
        </div>
      ) : robot ? (
        <div className="score__diff">&nbsp;</div>
      ) : null}
    </div>
  );
};

export default Score;
