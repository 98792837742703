import "./About.scss";

const Help = ({ scoring, onChangeScoring, onClose }) => {
  return (
    <div className="about__container" onClick={onClose}>
      <div className="about">
        <p>
          <span className="about__scoring">Scoring:</span>
          <span
            className={scoring === 'US' ? 'about__scoring-off' : 'about__scoring-on'}
            onClick={(event) => {
              onChangeScoring("UK");
              event.stopPropagation();
            }}
          >
            UK 🇬🇧
          </span>
          <span
            className={scoring === 'US' ? 'about__scoring-on' : 'about__scoring-off'}
            onClick={(event) => {
              onChangeScoring("US");
              event.stopPropagation();
            }}
          >
            US 🇺🇸
          </span>
        </p>
        <p>Created by Ian Wang</p>
        <p>
          <a className="about__mail-to" href="mailto:pokersquares@wangy.co.uk">
            pokersquares@wangy.co.uk
          </a>
        </p>
      </div>
    </div>
  );
};

export default Help;
